import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Button,
  Progress,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

import { useSocket } from "../App";
import { api_key, sdkURL } from "../utils/api-url-list";
import faceScan from "../utils/faceScan";

import Failure from "../assets/images/failure.gif";
import { ReactComponent as CheckFilled } from "../assets/icons/CheckFilled.svg";

const Scan = () => {
  const socket = useSocket();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation("Scan");
  const [analyzing, setAnalyzing] = useState(false);
  const [analyzingStep, setAnalyzingStep] = useState(0);
  const [error, setError] = useState("");
  const [scanFrameData, setScanFrameData] = useState({
    type: "",
    timeElapsed: 0,
    isLightMode: false,
    fps: 0,
  });

  const analyzingMsgDuration = 1000;
  const analyzingMsgList = useMemo(
    () => [
      t("analyzing_message_0"),
      t("analyzing_message_1"),
      t("analyzing_message_2"),
      t("analyzing_message_3"),
      t("analyzing_message_4"),
      t("analyzing_message_5"),
    ],
    [t]
  );

  useEffect(() => {
    let timer;
    if (analyzing) {
      if (analyzingStep < analyzingMsgList.length - 1) {
        timer = setTimeout(() => {
          setAnalyzingStep((s) => s + 1);
        }, analyzingMsgDuration);
      }
    } else setAnalyzingStep(0);
    return () => {
      clearTimeout(timer);
    };
  }, [analyzing, analyzingMsgList.length, analyzingStep]);

  useEffect(() => {
    if (state.token?.length > 0) {
      faceScan.onFrame((fd) => {
        const { landmarksList, tone, ...framedata } = fd;
        socket?.emit("framedata", fd);
        setScanFrameData(framedata);
      });
      faceScan.onScanFinish(({ raw_intensity, ppg_time, average_fps }) => {
        setAnalyzing(true);
        socket?.emit("analyzing");
        socket?.emit("playAudio", "analyzing");
        Promise.all([
          new Promise(async (resolve, reject) => {
            try {
              const resp = await fetch(sdkURL("/vitals/add-scan"), {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  employee_id: localStorage.getItem("auth_id"),
                  api_key,
                  scan_token: state.token,
                  posture: "Resting",
                  dob: state.dob,
                  gender: state.gender,
                  metadata: {
                    physiological_scores: {
                      height: state.height,
                      weight: state.weight,
                    },
                    device: `RPPG_CAREPLIX_FACE_${
                      navigator.platform.match(/iPhone|iPod|iPad/)
                        ? "IOS"
                        : "ANDROID"
                    }`,
                    ppg_time: ppg_time,
                    raw_intensity: raw_intensity,
                    fps: average_fps,
                  },
                }),
              });
              const resp_json = await resp.json();
              if (resp_json.statusCode?.toString().startsWith("2"))
                resolve(resp_json);
              else throw new Error(resp_json.message);
            } catch (err) {
              reject(err);
            }
          }),
          new Promise((resolve) => {
            setTimeout(resolve, analyzingMsgList.length * analyzingMsgDuration);
          }),
        ])
          .then(([result]) => {
            socket?.emit("scanresult", result);
            socket?.emit("playAudio", "result");
            navigate("/result", { state: { result }, replace: true });
          })
          .catch((err) => {
            console.error(err);
            socket?.emit("error", err.message);
            setError(err.message);
          });
      });
      faceScan.onError((err) => {
        console.error(err);
        socket?.emit("error", err.message);
        setError(err.message);
      });
      faceScan
        .startScan(60000, 60000, "/model")
        .then((resolution) => {
          console.log("Scan Started");
          socket?.emit("startscan", {
            resolution,
            userdata: {
              dob: state.dob,
              gender: state.gender,
              height: state.height,
              weight: state.weight,
            },
          });
        })
        .catch((err) => {
          console.error(err);
          socket?.emit("error", err.message);
        });
    } else setError("Invalid Scan Token");
    return () => {
      faceScan.stopScan(true);
    };
  }, [navigate, state, socket, analyzingMsgList.length]);

  useEffect(() => {
    if (scanFrameData.type === "calibration") {
      socket?.emit("playAudio", "calibration_start");
    } else if (scanFrameData.type === "scan") {
      socket?.emit("playAudio", "scan_start");
    }
  }, [scanFrameData.type, socket]);

  return (
    <section className="relative shrink-0 grow px-[15%]">
      <div className="relative h-[60vh] overflow-hidden">
        <video
          className="absolute bottom-12 left-8 w-px h-px bg-black"
          id="videoInput"
          autoPlay
          muted
          playsInline
        />
        <canvas
          id="canvasOutput"
          className="absolute h-full inset-y-0 left-1/2 -translate-x-1/2 -scale-x-100"
        />
        {!faceScan.isFaceInView() && (
          <p className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 px-4 py-2 rounded-xl bg-black/50 text-white text-sm sm:text-xl text-center font-medium whitespace-nowrap">
            {t("noFace")}
          </p>
        )}
        {faceScan.isInitializing() && (
          <div className="absolute inset-0 bg-[#fcfcfc] px-8 py-12 flex flex-col items-center justify-center text-center">
            <Spinner
              color="indigo"
              className="h-8 w-8 sm:h-12 sm:w-12 text-primary/20"
            />
            <Typography
              variant="h6"
              className="mt-4 text-xs sm:text-base text-primary font-medium"
            >
              {t("initializing")}
            </Typography>
          </div>
        )}
      </div>
      <div className="my-5 sm:my-10">
        {scanFrameData.type === "scan" && (
          <Progress
            value={(scanFrameData.timeElapsed - 10000) / 600}
            color="indigo"
            size="md"
            className="mb-3"
            barProps={{ className: "bg-primary text-white" }}
          />
        )}
        <Typography
          variant="h5"
          className="text-sm sm:text-xl text-primary text-center"
        >
          {scanFrameData.type === "scan"
            ? `${Math.round((scanFrameData.timeElapsed - 10000) / 600)}% ${t(
                "completed"
              )}`
            : `${Math.ceil((10000 - scanFrameData.timeElapsed) / 1000)} ${t(
                "seconds"
              )} ${t("start")}`}
        </Typography>
      </div>
      <Button
        color="white"
        className="mt-2 sm:mt-4 mb-4 w-full bg-white text-primary/80 border border-primary text-sm sm:text-lg font-medium normal-case"
        onClick={() => navigate(-1)}
      >
        {t("Cancel")}
      </Button>
      {error.length > 0 ? (
        <div className="absolute inset-0 bg-[#fcfcfc] px-8 py-12 flex flex-col items-center justify-start text-center">
          <img src={Failure} alt="failure icon" className="w-1/3 mx-auto" />
          <Typography
            variant="lead"
            className="mt-2.5 sm:mt-4 text-xs sm:text-base text-primary whitespace-pre-line"
          >
            {"Scan Failed!\nPlease try again."}
          </Typography>
          <Button
            color="white"
            className="mt-6 sm:mt-8 bg-white text-primary/80 border border-primary text-sm sm:text-lg normal-case"
            onClick={() => navigate(-1)}
          >
            {t("Back")}
          </Button>
        </div>
      ) : (
        analyzing && (
          <div className="absolute inset-0 bg-[#fcfcfc] p-[15%] pt-[5%] flex flex-col items-stretch justify-evenly gap-8">
            {analyzingMsgList.map((msg, index) => (
              <div
                key={index}
                className="flex items-center justify-between gap-3 sm:gap-6"
              >
                <Typography
                  variant="h4"
                  className="text-primary text-sm sm:text-2xl tracking-widest"
                >
                  {msg}
                </Typography>
                {analyzingStep === index ? (
                  <Spinner
                    color="indigo"
                    className="shrink-0 h-6 w-6 sm:h-12 sm:w-12 text-primary/20"
                  />
                ) : (
                  analyzingStep > index && (
                    <CheckFilled className="shrink-0 h-6 w-6 sm:h-12 sm:w-12 text-primary" />
                  )
                )}
              </div>
            ))}
          </div>
        )
      )}
    </section>
  );
};

export default Scan;
