import { createContext, useContext, useEffect, useState } from "react";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useRouteError,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";

import { ReactComponent as VendorLogo } from "./assets/logos/VendorLogo.svg";

import Login from "./screens/Login";
import GetStarted from "./screens/GetStarted";
import SelectOptions from "./screens/SelectOptions";
import StartScan from "./screens/StartScan";
import Scan from "./screens/Scan";
import Result from "./screens/Result";
// import DownloadReport from "./screens/DownloadReport";
import Contact from "./screens/Contact";
import Visualize from "./screens/Visualize";
import Visualize2 from "./screens/Visualize2";

const SocketContext = createContext({ socket: undefined });

export const useSocket = () => useContext(SocketContext).socket;

const Header = () => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const [socketInstance, setSocketInstance] = useState();

  useEffect(() => {
    const socket = io();
    setSocketInstance(socket);
    return () => {
      setSocketInstance();
      socket.disconnect();
    };
  }, []);

  return (
    <main className="min-h-screen flex flex-col relative bg-[#fcfcfc]">
      {pathname !== "/" && (
        <VendorLogo className="self-center shrink-0 my-8 sm:my-16 h-8 sm:h-16 text-primary" />
      )}
      <SocketContext.Provider value={{ socket: socketInstance }}>
        <Outlet />
      </SocketContext.Provider>
    </main>
  );
};

const ErrorReload = () => {
  const error = useRouteError();
  window.location.reload(true);
  console.error(error);
  return (
    <div className="h-screen p-12 flex items-center justify-center">
      <p className="text-center text-primary">Error Occurred!!!</p>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Header />,
    children: [
      { index: true, element: <GetStarted /> },
      { path: "/select-options", element: <SelectOptions /> },
      { path: "/start-scan", element: <StartScan /> },
      { path: "/scan", element: <Scan /> },
      { path: "/result", element: <Result /> },
      // { path: "/report", element: <DownloadReport /> },
    ],
  },
  {
    path: "/visualize",
    element: <Visualize />,
    errorElement: <ErrorReload />,
  },
  {
    path: "/visualize2",
    element: <Visualize2 />,
    errorElement: <ErrorReload />,
  },
  { path: "/login", element: <Login /> },
  { path: "/contact", element: <Contact /> },
]);

const App = () => <RouterProvider router={router} />;

export default App;
