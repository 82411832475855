import { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Carousel,
  Progress,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

import { useSocket } from "../App";
import { constants } from "../utils/ranges";

import { ReactComponent as HeartHealthScore } from "../assets/icons/HeartHealthScore.svg";
import { ReactComponent as MentalHealthScore } from "../assets/icons/MentalHealthScore.svg";
import { ReactComponent as PhysiologicalHealthScore } from "../assets/icons/PhysiologicalHealthScore.svg";
import { ReactComponent as HeartRate } from "../assets/icons/HeartRate.svg";
import { ReactComponent as RespirationRate } from "../assets/icons/RespirationRate.svg";
import { ReactComponent as OxygenSaturation } from "../assets/icons/OxygenSaturation.svg";
import { ReactComponent as BloodPressure } from "../assets/icons/BloodPressure.svg";

const ScoreItem = ({ dir = "", className = "", label = "", Icon, value }) => (
  <div
    dir={dir}
    className={`py-3 px-6 sm:py-6 sm:px-12 flex items-center justify-between gap-4 sm:gap-8 border-2 rounded-xl shadow-md ${className}`}
  >
    <div className="shrink-0 w-[20vw] text-primary">
      <Icon className="mx-auto mb-1.5 sm:mb-3 h-5 w-5 sm:h-10 sm:w-10 text-primary/80" />
      <Typography
        variant="h5"
        className="text-center text-[10px] sm:text-base font-medium leading-normal"
      >
        {label}
      </Typography>
    </div>
    <div className="grow">
      <Progress
        value={value}
        color="indigo"
        size="sm"
        barProps={{ className: "bg-primary text-white" }}
        className="mb-1.5 sm:mb-3"
      />
      <div className="flex items-end justify-between gap-2 sm:gap-6">
        <p className="text-xs sm:text-lg font-bold">
          <span className="text-primary">{value}</span>/
          <span className="text-primary/80">100</span>
        </p>
        <p className="text-black/70 text-[8px] sm:text-base tracking-widest uppercase font-medium">
          {
            constants["Score"].ranges.reduce((p, c) =>
              value > c.point ? c : p
            ).label
          }
        </p>
      </div>
    </div>
  </div>
);

const VitalItemWithIcon = ({ className = "", label = "", value = 0, Icon }) => (
  <div className={className}>
    <div className="flex items-start justify-between gap-1 sm:gap-2.5">
      <Typography
        variant="h5"
        className="text-xs sm:text-lg font-medium leading-normal"
      >
        {label}
      </Typography>
      <Icon className="shrink-0 p-1 sm:p-2 h-6 w-6 sm:h-10 sm:w-10 rounded-xl bg-stroke/25" />
    </div>
    <div className="mt-2.5 sm:mt-5 sm:space-y-1">
      <p>
        <span className="text-xl sm:text-3xl font-bold">{value}</span>
        <span className="ml-1.5 sm:ml-3 text-[10px] sm:text-base opacity-80">
          {constants[label].unit}
        </span>
      </p>
      <p className="text-black/70 text-[10px] sm:text-base tracking-widest uppercase font-medium">
        {
          constants[label].ranges.reduce(
            (p, c) => (value >= c.point ? c : p),
            0
          ).label
        }
      </p>
    </div>
  </div>
);

const VitalItem = ({
  className = "",
  label = "",
  value = 0,
  noRange = false,
}) => (
  <div className={`flex flex-col items-stretch justify-between ${className}`}>
    <Typography
      variant="h5"
      className="text-xs sm:text-lg font-medium leading-normal"
    >
      {label}
    </Typography>
    <div className="mt-2.5 sm:mt-5 sm:space-y-1">
      <p>
        <span className="text-xl sm:text-3xl font-bold">{value}</span>
        <span className="ml-1.5 sm:ml-3 text-[10px] sm:text-base opacity-80">
          {constants[label].unit}
        </span>
      </p>
      {!noRange && (
        <p className="text-black/70 text-[10px] sm:text-base tracking-widest uppercase font-medium">
          {
            constants[label].ranges.reduce(
              (p, c) => (value >= c.point ? c : p),
              0
            ).label
          }
        </p>
      )}
    </div>
  </div>
);

const VitalItemDetail = ({ label = "", ...props }) => (
  <div>
    <Typography variant="h5" className="text-primary text-sm sm:text-xl">
      {label}
    </Typography>
    <Typography
      variant="paragraph"
      className="mt-1.5 sm:mt-2.5 text-[10px] sm:text-sm text-black leading-tight"
    >
      {constants[label]?.info}
    </Typography>
    {props.children}
  </div>
);

const Result = () => {
  const { t, i18n } = useTranslation("Result");
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  const socket = useSocket();

  const result = state?.result;

  const [activeTab, setActiveTab] = useState("tab-0");

  return result?.scan_id?.length > 0 ? (
    <section className="shrink-0 grow px-[10%] flex flex-col items-stretch justify-between gap-4 sm:gap-6">
      <Tabs value={activeTab}>
        <Carousel
          dir="ltr"
          className="h-[62.5vh] items-stretch overflow-hidden"
          loop
          prevArrow={({ handlePrev }) => (
            <button
              type="button"
              onClick={handlePrev}
              className="absolute z-50 top-0 left-0 h-7 w-7 sm:h-14 sm:w-14 p-2 sm:p-4 rounded-lg sm:rounded-2xl bg-stroke/25 text-primary"
            >
              <svg className="h-full w-full" viewBox="0 0 22 38" fill="none">
                <path
                  d="M21.0919 0.843376C19.8959 -0.281124 17.9566 -0.281124 16.7606 0.843376L1.79202 14.9314C-0.598106 17.1807 -0.597185 20.8257 1.79386 23.0741L16.7716 37.1566C17.9676 38.2811 19.9069 38.2811 21.1029 37.1566C22.299 36.032 22.299 34.2087 21.1029 33.0842L8.28369 21.031C7.08741 19.9065 7.08741 18.0832 8.28369 16.9587L21.0919 4.91575C22.288 3.79125 22.288 1.96816 21.0919 0.843376Z"
                  fill="currentColor"
                />
              </svg>
              <span className="sr-only">Previous</span>
            </button>
          )}
          nextArrow={({ handleNext }) => (
            <button
              type="button"
              onClick={handleNext}
              className="absolute z-50 top-0 right-0 h-7 w-7 sm:h-14 sm:w-14 p-2 sm:p-4 rounded-lg sm:rounded-2xl bg-stroke/25 text-primary"
            >
              <svg className="h-full w-full" viewBox="0 0 22 38" fill="none">
                <path
                  d="M0.908109 37.1566C2.10412 38.2811 4.04342 38.2811 5.2394 37.1566L20.208 23.0686C22.5981 20.8193 22.5972 17.1743 20.2061 14.9259L5.22837 0.843416C4.03239 -0.281139 2.0931 -0.281139 0.897054 0.843416C-0.299018 1.968 -0.299018 3.79127 0.897054 4.91585L13.7163 16.969C14.9126 18.0935 14.9126 19.9168 13.7163 21.0413L0.908109 33.0843C-0.287963 34.2087 -0.287963 36.0318 0.908109 37.1566Z"
                  fill="currentColor"
                />
              </svg>
              <span className="sr-only">Next</span>
            </button>
          )}
          navigation={({ activeIndex }) => (
            <>
              <div
                dir={i18n.dir()}
                className="absolute w-full top-1 sm:top-2.5 left-1/2 -translate-x-1/2 text-center text-primary/80"
              >
                {activeIndex === 0 ? (
                  <Typography variant="h1" className="text-base sm:text-3xl">
                    {t("DigitalHealthReport")}
                  </Typography>
                ) : activeIndex === 1 ? (
                  <Typography variant="h1" className="text-base sm:text-3xl">
                    {t("ScanResult")}
                  </Typography>
                ) : (
                  <Typography variant="h1" className="text-base sm:text-3xl">
                    {t("VitalsDenotation")}
                  </Typography>
                )}
              </div>
              {activeIndex !== 0 && (
                <TabsHeader
                  dir={i18n.dir()}
                  className="absolute inset-x-0 top-12 sm:top-24 px-2.5 sm:px-5 py-0 rounded-full bg-stroke/25 items-center"
                  indicatorProps={{ className: "bg-transparent shadow-none" }}
                >
                  {[
                    t("KeyBodyVitals"),
                    t("HeartHealth"),
                    t("Physiological"),
                    t("StressANS"),
                  ].map((label, index) => (
                    <Tab
                      key={`tab-header-${index}`}
                      value={`tab-${index}`}
                      onClick={() => setActiveTab(`tab-${index}`)}
                      className={`py-2.5 px-1.5 ${
                        activeTab === `tab-${index}`
                          ? "text-primary"
                          : "text-primary/60"
                      } text-[10px] sm:text-base font-semibold`}
                    >
                      {label}
                    </Tab>
                  ))}
                </TabsHeader>
              )}
            </>
          )}
        >
          <div className="pt-10 sm:pt-20 h-full">
            <div className="p-2 sm:p-4 h-full overflow-y-auto">
              <div className="flex items-center justify-between gap-2 sm:gap-4">
                <ScoreItem
                  dir={i18n.dir()}
                  className="grow"
                  label={t("MentalHealthScore")}
                  value={Math.round(result.metadata.mental_wellbeing_score)}
                  Icon={MentalHealthScore}
                />
                <div className="relative shrink-0 w-4 h-4 sm:w-8 sm:h-8 rotate-90">
                  <p
                    dir={i18n.dir()}
                    className="vertical-lined-text w-16 sm:w-32 text-primary/70"
                  >
                    <span className="text-primary text-[10px] sm:text-base tracking-[3px] uppercase font-medium">
                      {t("Mind")}
                    </span>
                  </p>
                </div>
              </div>
              <div className="mt-4 sm:mt-6 flex items-center justify-between gap-2 sm:gap-4">
                <div className="grow">
                  <ScoreItem
                    dir={i18n.dir()}
                    label={t("HeartHealthScore")}
                    value={Math.round(result.metadata.overall_heart_score)}
                    Icon={HeartHealthScore}
                  />
                  <ScoreItem
                    dir={i18n.dir()}
                    className="mt-4 sm:mt-6"
                    label={t("PhysiologicalHealthScore")}
                    value={Math.round(result.metadata.physiological_score)}
                    Icon={PhysiologicalHealthScore}
                  />
                </div>
                <div className="relative shrink-0 w-4 h-4 sm:w-8 sm:h-8 rotate-90">
                  <p
                    dir={i18n.dir()}
                    className="vertical-lined-text w-40 sm:w-72 text-primary/70"
                  >
                    <span className="text-primary text-[10px] sm:text-base tracking-[3px] uppercase font-medium">
                      {t("Body")}
                    </span>
                  </p>
                </div>
              </div>
              <div
                dir={i18n.dir()}
                className="mt-4 sm:mt-6 flex items-center justify-center gap-4 sm:gap-6"
              >
                <Typography
                  variant="h5"
                  className="text-primary text-center text-sm sm:text-2xl font-semibold leading-normal"
                >
                  {t("OverallHealthScore")}
                </Typography>
                <div
                  dir="ltr"
                  className="shrink-0 w-20 sm:w-32 p-1.5 bg-[#f3f6fb] rounded-full relative"
                >
                  <ResponsiveContainer aspect={1}>
                    <PieChart
                      margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
                      className="bg-white text-primary rounded-full"
                    >
                      <Pie
                        data={[
                          { value: result.wellness_score ?? 0 },
                          { value: 100 - (result.wellness_score ?? 0) },
                        ]}
                        dataKey="value"
                        startAngle={270}
                        endAngle={-90}
                        innerRadius="90%"
                        outerRadius="100%"
                        cornerRadius="100%"
                      >
                        <Cell fill="currentColor" stroke="none" />
                        <Cell fill="transparent" stroke="none" />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                  <p className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 font-bold text-black">
                    <span className="text-base sm:text-3xl">
                      {result.wellness_score}
                    </span>
                    <span className="text-sm sm:text-2xl">%</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-28 sm:pt-40 h-full">
            <TabsBody className="h-full">
              <TabPanel
                value="tab-0"
                className="h-full overflow-y-auto text-primary"
              >
                <div className="grid grid-cols-2 divide-x divide-primary/20">
                  <VitalItemWithIcon
                    className="pb-2 sm:pb-4 pr-4 sm:pr-8"
                    Icon={HeartRate}
                    label="Heart Rate"
                    value={result.vitals.heart_rate}
                  />
                  <VitalItemWithIcon
                    className="pb-2 sm:pb-4 pl-4 sm:pl-8"
                    Icon={RespirationRate}
                    label="Respiration Rate"
                    value={result.vitals.resp_rate}
                  />
                </div>
                <div className="mt-4 sm:mt-6 grid grid-cols-2 divide-x divide-primary/20">
                  <VitalItemWithIcon
                    className="pb-2 sm:pb-4 pr-4 sm:pr-8"
                    Icon={OxygenSaturation}
                    label="Oxygen Saturation"
                    value={result.vitals.oxy_sat_prcnt}
                  />
                  <div className="pb-2 sm:pb-4 pl-4 sm:pl-8">
                    <div className="flex items-start justify-between gap-1 sm:gap-2.5">
                      <Typography
                        variant="h5"
                        className="text-xs sm:text-lg font-medium leading-normal"
                      >
                        Blood Pressure
                      </Typography>
                      <BloodPressure className="shrink-0 p-1 sm:p-2 h-6 w-6 sm:h-10 sm:w-10 rounded-xl bg-stroke/25" />
                    </div>
                    <div className="relative mt-2.5 sm:mt-5">
                      <p>
                        <span className="text-xl sm:text-3xl font-bold">
                          {result.vitals.bp_sys}
                        </span>
                        <span className="ml-1.5 sm:ml-3 text-[10px] sm:text-base opacity-80">
                          {constants["Blood Pressure"].unit}
                        </span>
                      </p>
                      <p className="mt-0.5 sm:mt-1 text-black/70 text-[10px] sm:text-base tracking-widest uppercase font-medium">
                        {
                          constants["Blood Pressure"].ranges[0].reduce(
                            (p, c) => (result.vitals.bp_sys > c.point ? c : p),
                            0
                          ).label
                        }
                      </p>
                      <p className="vertical-text">Systolic</p>
                    </div>
                    <div className="relative mt-2.5 sm:mt-5">
                      <p>
                        <span className="text-xl sm:text-3xl font-bold">
                          {result.vitals.bp_dia}
                        </span>
                        <span className="ml-1.5 sm:ml-3 text-[10px] sm:text-base opacity-80">
                          {constants["Blood Pressure"].unit}
                        </span>
                      </p>
                      <p className="mt-0.5 sm:mt-1 text-black/70 text-[10px] sm:text-base tracking-widest uppercase font-medium">
                        {
                          constants["Blood Pressure"].ranges[1].reduce(
                            (p, c) => (result.vitals.bp_dia > c.point ? c : p),
                            0
                          ).label
                        }
                      </p>
                      <p className="vertical-text">Diastolic</p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel
                value="tab-1"
                className="h-full overflow-y-auto grid grid-cols-2 sm:grid-cols-3 content-start justify-center text-primary"
              >
                <VitalItem
                  className="pb-2 pr-3 border-r border-b border-primary/20"
                  label="SDNN"
                  value={Math.round(result.metadata.heart_scores.sdnn)}
                />
                <VitalItem
                  className="pb-2 pl-3 sm:pr-3 border-b sm:border-r border-primary/20"
                  label="RMSSD"
                  value={Math.round(result.metadata.heart_scores.rmssd)}
                />
                <VitalItem
                  className="py-2 sm:pt-0 pr-3 sm:pr-0 sm:pl-3 border-r sm:border-r-0 border-b border-primary/20"
                  label="PNN50"
                  value={result.metadata.heart_scores.pNN50_per}
                />
                <VitalItem
                  className="py-2 pl-3 sm:pl-0 sm:pr-3 sm:border-r border-b border-primary/20"
                  label="Cardiac Output"
                  value={result.metadata.cardiovascular.cardiac_out}
                />
                <VitalItem
                  className="py-2 pr-3 sm:pl-3 border-r border-b border-primary/20"
                  label="Mean Arterial Pressure"
                  value={result.metadata.cardiovascular.map}
                />
                <VitalItem
                  className="py-2 pl-3 border-b border-primary/20"
                  label="Heart Utilized"
                  value={result.metadata.heart_scores.heart_utilized}
                />
                <VitalItem
                  className="py-2 pr-3 border-r border-b border-primary/20"
                  label="Heart Rate Max"
                  value={result.metadata.heart_scores.HRMax}
                  noRange
                />
                <VitalItem
                  className="py-2 pl-3 sm:pr-3 sm:border-r border-b border-primary/20"
                  label="Heart Rate Reserve"
                  value={result.metadata.heart_scores.HRR}
                  noRange
                />
                <VitalItem
                  className="pt-2 sm:pb-2 pr-3 sm:pr-0 sm:pl-3 border-r sm:border-r-0 sm:border-b border-primary/20"
                  label="Target HR Range"
                  value={result.metadata.heart_scores.THRR}
                  noRange
                />
                <VitalItem
                  className="pt-2 pl-3 sm:pl-0 sm:pr-3 sm:border-r sm:border-primary/20"
                  label="VO₂ Max"
                  value={result.metadata.physiological_scores.vo2max}
                  noRange
                />
                <div className="hidden sm:block sm:border-r sm:border-primary/20" />
              </TabPanel>
              <TabPanel
                value="tab-2"
                className="h-full overflow-y-auto grid grid-cols-2 sm:grid-cols-3 content-start text-primary"
              >
                <div className="flex flex-col items-stretch justify-between pb-2 pr-3 border-b border-r border-primary/20">
                  <Typography
                    variant="h5"
                    className="text-xs sm:text-lg font-medium leading-normal"
                  >
                    BMI
                  </Typography>
                  <div className="mt-2.5 sm:mt-5 sm:space-y-1">
                    <p>
                      <span className="text-xl sm:text-3xl font-bold">
                        {result.metadata.physiological_scores.bmi}
                      </span>
                      <span className="ml-1.5 sm:ml-3 text-[10px] sm:text-base opacity-80">
                        {constants["BMI"].unit}
                      </span>
                    </p>
                    <p className="text-black/70 text-[10px] sm:text-base tracking-widest uppercase font-medium">
                      {
                        constants["BMI"].ranges.reduce(
                          (p, c) =>
                            result.metadata.physiological_scores.bmi > c.point
                              ? c
                              : p,
                          0
                        ).label
                      }
                    </p>
                  </div>
                </div>
                <VitalItem
                  className="!justify-start pb-2 pl-3 sm:pr-3 border-b sm:border-r border-primary/20"
                  label="Blood Volume"
                  value={result.metadata.physiological_scores.bloodvolume}
                  noRange
                />
                <VitalItem
                  className="sm:!justify-start py-2 sm:pt-0 pr-3 sm:pr-0 sm:pl-3 border-b border-r sm:border-r-0 border-primary/20"
                  label="Total Body Water"
                  value={result.metadata.physiological_scores.tbw}
                  noRange
                />
                <VitalItem
                  className="py-2 sm:pb-0 pl-3 sm:pl-0 sm:pr-3 border-b sm:border-b-0 sm:border-r border-primary/20"
                  label="Body Water %"
                  value={result.metadata.physiological_scores.tbwp}
                  noRange
                />
                <VitalItem
                  className="pt-2 pr-3 sm:pl-3 border-r border-primary/20"
                  label="Total Body Fat"
                  value={result.metadata.physiological_scores.bodyfat}
                  noRange
                />
                <VitalItem
                  className="pt-2 pl-3"
                  label="Body Weight"
                  value={result.metadata.physiological_scores.weight}
                  noRange
                />
              </TabPanel>
              <TabPanel value="tab-3" className="h-full overflow-y-auto">
                {(() => {
                  const stressLevel =
                    result?.metadata.heart_scores.stress_index;
                  const selectedRangeIndex = constants[
                    "Stress Level"
                  ].ranges.reduce(
                    (p, c, ci) => (stressLevel > c.point ? ci : p),
                    0
                  );
                  const selectedRange =
                    constants["Stress Level"].ranges[selectedRangeIndex];
                  const { Icon } = selectedRange;
                  let detailTitle = "";
                  let detailDescription = "";
                  if (
                    (result?.metadata?.heart_scores?.rmssd ?? "--") !== "--"
                  ) {
                    if (result.metadata.heart_scores.rmssd < 65) {
                      if (
                        result.vitals.heart_rate > 100 ||
                        result.vitals.bp_sys > 130 ||
                        result.vitals.bp_dia > 90
                      ) {
                        detailTitle = "More Sympathetic Interactions";
                        detailDescription =
                          "Although HRV manifests as a function of your heart rate, it actually originates from your nervous system. Your autonomic nervous system, which controls the involuntary aspects of your physiology, has two branches, parasympathetic (deactivating) and sympathetic (activating).\n\nThe sympathetic nervous system (often called “fight or flight”) reflects responses to things like stress and exercise, and increases your heart rate and blood pressure.";
                      } else {
                        detailTitle = "More Parasympathetic Interactions";
                        detailDescription =
                          "Although HRV manifests as a function of your heart rate, it actually originates from your nervous system. Your autonomic nervous system, which controls the involuntary aspects of your physiology, has two branches, parasympathetic (deactivating) and sympathetic (activating)\n\nThe parasympathetic nervous system (often referred to as “rest and digest”) handles inputs from internal organs, like digestion or your fingernails and hair growing.";
                      }
                    } else {
                      detailTitle = "Balanced ANS Interactions";
                      detailDescription =
                        "When you have high heart rate variability, it means that your body is responsive to both sets of inputs (parasympathetic and sympathetic). This is a sign that your nervous system is balanced, and that your body is very capable of adapting to its environment and performing at its best.";
                    }
                  } else {
                    detailDescription =
                      "Not enought data to process. This happens when the signal strength of the scan is poor due to improper light or too much movements.";
                  }
                  return (
                    <>
                      <div className="sm:mx-[7.5%] px-4 sm:px-6 py-6 sm:py-8 rounded-2xl bg-stroke/[0.15] text-primary">
                        <div className="flex items-start justify-between gap-3 sm:gap-6">
                          <Icon className="shrink-0 h-10 w-10 sm:h-16 sm:w-16" />
                          <div className="grow space-y-1.5 sm:space-y-2">
                            <h4 className="text-xs sm:text-lg font-semibold">
                              Stress Level
                            </h4>
                            <p className="text-black/70 text-[10px] sm:text-base tracking-widest uppercase font-medium">
                              {selectedRange.label}
                            </p>
                          </div>
                          <div className="shrink-0 text-right">
                            <p className="text-xl sm:text-3xl font-bold">
                              {result.metadata.heart_scores.stress_index}
                            </p>
                            <p className="text-[10px] sm:text-base opacity-80">
                              Stress Index (SI)
                            </p>
                          </div>
                        </div>
                        <div className="mt-2.5 sm:mt-5">
                          <h3 className="text-xs sm:text-lg font-semibold">
                            Your Stress Level is {selectedRange.label}
                          </h3>
                          <p className="mt-1.5 sm:mt-2 text-black/70 text-[10px] sm:text-base leading-snug">
                            {constants["Stress Level"].info}
                          </p>
                        </div>
                      </div>
                      <div className="mt-4 sm:mt-8 text-center">
                        {detailTitle.length > 0 && (
                          <h3 className="mb-1.5 sm:mb-3 text-primary text-[10px] sm:text-sm font-medium">
                            {detailTitle}
                          </h3>
                        )}
                        <p className="text-black text-[8px] sm:text-xs">
                          {detailDescription}
                        </p>
                        <h5 className="mt-2 sm:mt-4 text-primary/80 text-[10px] sm:text-sm font-medium">
                          Autonomic Nervous Systems (ANS)
                        </h5>
                      </div>
                    </>
                  );
                })()}
              </TabPanel>
            </TabsBody>
          </div>

          <div className="pt-28 sm:pt-40 h-full">
            <TabsBody className="h-full">
              <TabPanel
                value="tab-0"
                className="flex flex-col items-stretch gap-4 sm:gap-6 h-full overflow-y-auto"
              >
                <VitalItemDetail label="Heart Rate" />
                <VitalItemDetail label="Respiration Rate" />
                <VitalItemDetail label="Oxygen Saturation" />
                <VitalItemDetail label="Blood Pressure">
                  {[
                    "Systolic pressure - the pressure when your heart pushes blood out",
                    "Diastolic pressure - the pressure when your heart rests between beats",
                  ].map((item, index) => (
                    <p
                      key={index}
                      className="mt-2 sm:mt-4 flex items-center gap-2.5 sm:gap-4"
                    >
                      <svg
                        className="shrink-0 h-3 w-3 sm:h-4 sm:w-4 text-primary"
                        viewBox="0 0 400 400"
                      >
                        <path
                          d="M51 386 c-9 -11 -4 -33 24 -100 l35 -85 -35 -86 c-29 -68 -34 -90 -24 -101 6 -8 17 -14 23 -14 13 0 241 152 271 181 19 19 19 19 0 38 -30 29 -258 181 -271 181 -6 0 -17 -6 -23 -14z m167 -95 c67 -45 122 -86 122 -91 0 -11 -250 -180 -267 -180 -18 0 -17 8 17 90 17 40 30 81 30 90 0 9 -13 50 -30 90 -16 40 -30 77 -30 83 0 18 37 -1 158 -82z"
                          fill="currentColor"
                        />
                        <path
                          d="M130 297 c0 -3 9 -26 20 -51 19 -46 19 -46 -1 -92 -11 -26 -18 -48 -16 -50 4 -4 146 89 146 96 0 3 -21 19 -47 36 -26 17 -59 39 -74 48 -16 10 -28 16 -28 13z"
                          fill="currentColor"
                        />
                      </svg>
                      <span className="text-[10px] sm:text-sm text-black leading-tight">
                        {item}
                      </span>
                    </p>
                  ))}
                </VitalItemDetail>
              </TabPanel>
              <TabPanel
                value="tab-1"
                className="flex flex-col items-stretch gap-4 sm:gap-6 h-full overflow-y-auto"
              >
                <VitalItemDetail label="SDNN" />
                <VitalItemDetail label="RMSSD" />
                <VitalItemDetail label="PNN50" />
                <VitalItemDetail label="Cardiac Output" />
                <VitalItemDetail label="Mean Arterial Pressure" />
                <VitalItemDetail label="Heart Utilized" />
                <VitalItemDetail label="Heart Rate Max" />
                <VitalItemDetail label="Heart Rate Reserve" />
                <VitalItemDetail label="Target HR Range" />
                <VitalItemDetail label="VO₂ Max" />
              </TabPanel>
              <TabPanel
                value="tab-2"
                className="flex flex-col items-stretch gap-4 sm:gap-6 h-full overflow-y-auto"
              >
                <VitalItemDetail label="BMI" />
                <VitalItemDetail label="Blood Volume" />
                <VitalItemDetail label="Total Body Water" />
                <VitalItemDetail label="Body Water %" />
                <VitalItemDetail label="Total Body Fat" />
              </TabPanel>
              <TabPanel
                value="tab-3"
                className="flex flex-col items-stretch gap-4 sm:gap-6 h-full overflow-y-auto"
              >
                <VitalItemDetail label="Stress Level" />
              </TabPanel>
            </TabsBody>
          </div>
        </Carousel>
      </Tabs>
      <div
        dir={i18n.dir()}
        className="mb-8 sm:mb-16 flex flex-col items-stretch justify-between gap-5 sm:gap-8"
      >
        {/* <Button
          color="white"
          className="flex-1 flex items-center justify-center gap-2 bg-primary text-white border border-primary text-sm sm:text-lg normal-case"
          onClick={() =>
            navigate("/report", { state: { scan_id: result.scan_id } })
          }
        >
          <svg
            className="shrink-0 h-4 w-4 sm:h-8 sm:w-8"
            viewBox="0 0 56 42"
            fill="none"
          >
            <path
              d="M17.3333 15.75C17.3333 9.95101 22.109 5.25 28 5.25C33.8909 5.25 38.6667 9.95101 38.6667 15.75V18.375H41.3333C46.488 18.375 50.6667 22.4884 50.6667 27.5625C50.6667 32.6366 46.488 36.75 41.3333 36.75H41.0667C39.5939 36.75 38.4 37.9252 38.4 39.375C38.4 40.8248 39.5939 42 41.0667 42H41.3333C49.4336 42 56 35.5362 56 27.5625C56 20.4207 50.732 14.4899 43.8123 13.3304C42.6299 5.78059 36.0008 0 28 0C19.9993 0 13.3702 5.78059 12.1876 13.3304C5.26803 14.4899 0 20.4207 0 27.5625C0 35.5362 6.56648 42 14.6667 42H14.9333C16.4061 42 17.6 40.8248 17.6 39.375C17.6 37.9252 16.4061 36.75 14.9333 36.75H14.6667C9.512 36.75 5.33333 32.6366 5.33333 27.5625C5.33333 22.4884 9.512 18.375 14.6667 18.375H17.3333V15.75ZM30.6667 18.375C30.6667 16.9252 29.4728 15.75 28 15.75C26.5272 15.75 25.3333 16.9252 25.3333 18.375V33.0377L21.8856 29.6439C20.8442 28.6188 19.1558 28.6188 18.1144 29.6439C17.073 30.6689 17.073 32.3311 18.1144 33.3561L26.1144 41.2311C27.1557 42.2562 28.8443 42.2562 29.8856 41.2311L37.8856 33.3561C38.9269 32.3311 38.9269 30.6689 37.8856 29.6439C36.8443 28.6188 35.1557 28.6188 34.1144 29.6439L30.6667 33.0377V18.375Z"
              fill="currentColor"
              fillRule="evenodd"
              clipRule="evenodd"
            />
          </svg>
          <span>{t("DownloadReport")}</span>
        </Button> */}
        <Button
          color="white"
          className="flex-1 bg-white text-primary/80 border border-stroke text-sm sm:text-lg normal-case"
          onClick={() => {
            socket?.emit("playAudio", "reset");
            navigate("/", { replace: true });
          }}
        >
          {t("GoBack")}
        </Button>
      </div>
    </section>
  ) : (
    <Navigate to="/" replace />
  );
};

export default Result;
